import './Work.css';

function Work() {
    return <div className="work-content">
       <p>I make websites (among other things).</p>
       <p>Will post my portfolio here soon.</p>
    </div>
   }
   
   export default Work;
   